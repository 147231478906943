body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body, html{
  background: #efefef;
}

.start {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 30px;
}

.start button {
  user-select: none;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 7px solid #8fd4f5;
  background: #56c8ff;
  box-shadow: 1px 1px 10px #8fd4f5;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
}

.start .status-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #b7b7b7;
  background: #9b9b9b;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  animation: loading 1s infinite;
  animation-direction: alternate-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0%{
    box-shadow: 1px 1px 10px #a7a7a7;
    width: 150px;
    height: 150px;
    border: 3px solid #b7b7b7;
  }
  100%{
    box-shadow: 1px 1px 20px #a7a7a7;
    width: 170px;
    height: 170px;
    border: 11px solid #b7b7b7;
  }
}

.start button:hover{
  transform: scale(110%);
  box-shadow: 1px 1px 14px #b3e6ff;
  border: 11px solid #8fd4f5;
}

.start button.rec{
  border: 7px solid #f58f8f;
  background: #ff5656;
  box-shadow: 1px 1px 10px #f58f8f;
}
.start button.rec:hover{
  transform: scale(110%);
  background: #ff5656;
  border: 11px solid #ff5656;
  box-shadow: 1px 1px 14px #f58f8f;
}

.start button:disabled {
  border: 7px solid #b7b7b7;
  background: #9b9b9b;
  box-shadow: 1px 1px 10px #a7a7a7;
  color: #ffffff8a;
}

.select{
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: 90px;
  justify-content: center;
  height: 40px;
  width: 100%;
  z-index: 2;
}

.select select{
  max-width: 240px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  background: #fff;
  border: 1px solid #56c8ff;
  box-shadow: 1px 1px 10px #8fd4f5;
}